body {
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #f4f4f4;
    box-sizing: border-box;
    
  }
  
  .heading {
    margin-top: 2rem;
    font-size: 3rem;
    font-weight: bold;
    color: #fcfcfc;
    padding-block: 5rem;
    padding-bottom: 40px;
    text-shadow: 6px 2px 4px #ff0000;
  }
  
  .middle-section {
    text-align: center;
    background-color: #1a1a1a;
    margin: auto;
    margin-bottom: 40px;
    width: 100%;
 }
  
  .tabs {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabs button {
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .tabs button.active {
    background-color: #ff4d4d
  }
  
  .tabs button:hover {
    background-color: #666666;
  }
  
  .content-container {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;  
      margin: 20px auto;
      
    
  }
  
  .event-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    max-width: 600px;
    text-align: left;
  }
  .para{
    
    text-align: left;
  }
  
  .rounds-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  .round-card {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .round-card h3 {
    margin-bottom: 10px;
    color: #ff4d4d;
  }
  
  .round-card p {
    margin: 5px 0;
    line-height: 1.5;
  }
  
  .reg-button {
    margin: 20px;
    background-color: #ff4d4d;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    cursor: not-allowed;
    border-radius: 4px;
    
  }
  .but{
    text-align: center;
  }

  .logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .intern-name{
    text-align: center;
    font-weight: bold;
    font-size:25px;
  }
 
  
  @media (max-width: 768px) {
    .tabs {
      gap: 5px;
    }
  .logo-container{
    text-align: center;
  }
    .tabs button {
      width: 100%;
    }
  
    .content {
      text-align:left;
    }
    .content-container{
      width: 100%;
    }
    .content-container img {
        margin-right: 0;
        margin-bottom: 20px;
       width: 70%;
        margin: auto;
    }
  
.para{
    padding:0 25px ;
}
  }
  

  @media(max-width:1200px)
  {
    .content-container{
        width: 100%;
        margin:25px auto;
        
    }
  }

  @media(max-width:420px)
  {
    .par{
      font-size: 15px;
    }
  }
  @media(max-width:360px)
  {
    .par{
      padding: 0 10px;
  
    }
  }
  